
import {listFloor} from "@/api/system/floor";


const useFloorStore = defineStore(
  'floor',
  {
    state: () => ({
      floorList: []
    }),
    actions: {
      getFloorList(){
        return new Promise(resolve => {
          listFloor().then(response => {
            response.data.forEach(item => {
              this.floorList.push({
                key: item.id,
                value: item.floorName
              });
            });
            resolve()
          })
        })

      },
      getFloorText(floorId) {
        if (floorId == null || floorId == "") {
          return null;
        }
        try {
          for (let i = 0; i < this.floorList.length; i++) {
            if (this.floorList[i].key == floorId) {
              return this.floorList[i].value;
            }
          }
        } catch (e) {
          return null;
        }
      },

      setFloorList(key, value) {
        console.log("setFloorList")
        if (key !== null && key !== "") {
          this.floorList.push({
            key: key,
            value: value
          });
        }
      },

      removeFloorList(key) {
        let bln = false;
        try {
          for (let i = 0; i < this.floorList.length; i++) {
            if (this.floorList[i].key == key) {
              this.floorList.splice(i, 1);
              return true;
            }
          }
        } catch (e) {
          bln = false;
        }
        return bln;
      },
      // 清空字典
      cleanFloorList() {
        this.floorList = [];
      },
      // 初始字典
      initFloorList() {
      }
    }
  })

export default useFloorStore
